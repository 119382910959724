const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\lakeStats\\lakeStats.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Col, Row, Typography } from "antd";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "@opendash/router";
import { Carousel } from "../carousel";
import {
  StarFilled,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";

import { useLakeImages, useLakeMetaData } from "../../hooks/useLakeMetaData";
import MultiColorProgressbar from "multi-color-progressbar-with-indicator";
import "multi-color-progressbar-with-indicator/dist/index.css";
import Parse from "parse";

// interface Geography {
//   id: string;
// }

// type LakeStatsType = {
//   id: string;
//   name: string;
//   area: number;
//   swimmingUsage: boolean;
//   district: string;
//   circumference: number;
// };











const IconLabelComponent = ({
  icon: Icon,
  label,
}) => {
  return (
    React.createElement('div', { style: { display: "flex", alignItems: "center", marginTop: "4%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
        , React.createElement(Icon, {
          style: {
            fontSize: "20px",
            padding: "6px",
            backgroundColor: "#56ECAD",
            borderRadius: "50%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        )
      )
      , React.createElement(Text, { style: { fontSize: "16px", fontWeight: "600", marginLeft: "5%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        , label
      )
    )
  );
};

const { Title, Text } = Typography;

const PropertyRow = ({ label, value }) => (
  React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
    , React.createElement(Text, { style: { flex: 0.35, fontSize: "15px", fontWeight: "600" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      , label, ":"
    )
    , React.createElement(Text, { style: { flex: 0.65, fontSize: "15px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, value)
  )
);

const LakeStats = ({}) => {
  const { lakeId } = useParams();
  const location = useLocation();
  const {
    item: { sensors },
  } = location.state || {};

  const t = useTranslation();

  const { result: properties } = useLakeMetaData();

  const currentLake = properties.find(
    (item) => _optionalChain([item, 'access', _ => _.geography, 'optionalAccess', _2 => _2.id]) === lakeId
  ) || {
    id: undefined,
    name: "",
    area: 0,
    swimmingUsage: false,
    district: "",
    circumference: 0,
    volume: 0,
    averageDepth: 0,
    maximumDepth: 0,
  };
  const { result: images } = useLakeImages(_optionalChain([currentLake, 'optionalAccess', _3 => _3.id]));

  const wqiBars = [
    { width: 30, color: "#dc3545" },
    { width: 40, color: "#f2d261" },
    { width: 30, color: "#6fa053" },
  ];

  const [wqiValues, setWqiValues] = useState({
    minVal: 0,
    maxVal: 1000,
    value: 0,
    trend: 0,
  });

  const getWQI = async () => {
    if (lakeId) {
      // Query to get the maximum value
      const maxQuery = new Parse.Query("AD4GD_Lake_Quality_Index");
      const maxResults = await maxQuery
        .select("WQI_status")
        .ascending("WQI_status")
        .limit(1)
        .find();

      const maxValue =
        maxResults.length > 0 ? maxResults[0].get("WQI_status") : null;

      // Query to get the minimum value
      const minQuery = new Parse.Query("AD4GD_Lake_Quality_Index");
      const minResults = await minQuery
        .select("WQI_status")
        .descending("WQI_status")
        .limit(1)
        .find();

      const minValue =
        minResults.length > 0 ? minResults[0].get("WQI_status") : null;

      // Query to get the value of current lake
      const query = new Parse.Query("AD4GD_Lake_Quality_Index");
      query.equalTo("lake", currentLake.name);
      const results = await query.find();

      if (results.length > 0) {
        const lakeObject = results[0];
        const wqiStatus = lakeObject.get("WQI_status");
        const wqiTrend = lakeObject.get("WQI_trend");

        // Set the state with the values
        setWqiValues({
          minVal: minValue || 0,
          maxVal: maxValue || 1000,
          value: wqiStatus || 0,
          trend: wqiTrend || 0,
        });
      } else {
        console.log(`No lake found with the name: ${currentLake.name}`);
      }
    }
  };

  useEffect(() => {
    if (currentLake.name) {
      getWQI();
    }
  }, [currentLake.name]);

  const renderWQIArrow = () => {
    if (wqiValues.trend > 0) {
      return (
        React.createElement(ArrowUpOutlined, {
          style: { color: "green", fontSize: "20px", marginRight: "5px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
        )
      );
    } else if (wqiValues.trend < 0) {
      return (
        React.createElement(ArrowDownOutlined, {
          style: { color: "red", fontSize: "20px", marginRight: "5px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
        )
      );
    }
    return null;
  };
  const config = useMemo(() => {
    return {
      _sources: [],
      _items: [],
      _dimensions: [...sensors],
      _history: {},
    };
  }, [sensors]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Row, { style: { width: "100%", height: "80px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 194}}
        , React.createElement(WidgetStatic, {
          style: { width: "100%", height: "100%" },
          type: "header-widget",
          config: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 195}}
)
      )
      , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}
        , React.createElement('div', {
          style: {
            width: "30%",
            backgroundColor: "white",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}

          , React.createElement(Title, {
            level: 1,
            style: {
              fontWeight: "bold",
              marginBottom: "2%",
              paddingLeft: "1rem",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}

            , currentLake.name
          )
          , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 218}}
            , React.createElement(Col, {
              style: {
                alignSelf: "center",
                paddingLeft: "1rem",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 219}}

              , React.createElement('svg', { width: "12", height: "12", __self: this, __source: {fileName: _jsxFileName, lineNumber: 225}}
                , React.createElement('circle', { cx: "6", cy: "6", r: "6", fill: "#55b169", __self: this, __source: {fileName: _jsxFileName, lineNumber: 226}} )
              )
              , React.createElement(Text, {
                strong: true,
                style: {
                  lineHeight: 0,
                  fontSize: "15px",
                  marginLeft: "5px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}

                , currentLake.name
              )
            )
          )

          , React.createElement(Carousel, {
            images: images.map((image) => [
              image.image._url,
              image.description,
            ]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 241}}
          )

          , React.createElement('div', { style: { marginTop: "6%", paddingLeft: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 248}}
            , React.createElement(PropertyRow, { label: t("Name"), value: currentLake.name, __self: this, __source: {fileName: _jsxFileName, lineNumber: 249}} )
            , React.createElement(PropertyRow, { label: t("Fläche"), value: currentLake.area + " m²", __self: this, __source: {fileName: _jsxFileName, lineNumber: 250}} )
            , React.createElement(PropertyRow, {
              label: t("Badenutzung"),
              value: currentLake.swimmingUsage ? "Ja" : "Nein", __self: this, __source: {fileName: _jsxFileName, lineNumber: 251}}
            )
            , React.createElement(PropertyRow, { label: t("Bezirk"), value: currentLake.district, __self: this, __source: {fileName: _jsxFileName, lineNumber: 255}} )
            , React.createElement(PropertyRow, {
              label: t("Umfang"),
              value: currentLake.circumference + " m", __self: this, __source: {fileName: _jsxFileName, lineNumber: 256}}
            )
            , React.createElement(PropertyRow, {
              label: t("Volumen"),
              value: currentLake.volume + " m³", __self: this, __source: {fileName: _jsxFileName, lineNumber: 260}}
            )
            , React.createElement(PropertyRow, {
              label: t("Durchnittliche Tiefe"),
              value: currentLake.averageDepth + " m", __self: this, __source: {fileName: _jsxFileName, lineNumber: 264}}
            )
            , React.createElement(PropertyRow, {
              label: t("Maximale Tiefe"),
              value: currentLake.maximumDepth + " m", __self: this, __source: {fileName: _jsxFileName, lineNumber: 268}}
            )
          )

          , React.createElement(IconLabelComponent, {
            icon: StarFilled,
            label: "Als Favorit hinzufügen"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 274}}
          )
        )

        , React.createElement('div', {
          style: {
            width: "68%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "2%",
            marginLeft: "1%",
            marginTop: "1%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 280}}

          , React.createElement('div', {
            style: {
              width: "100%",
              height: "40%",
              marginBottom: "1%",
              borderRadius: "20px",
              backgroundColor: "white",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 292}}

            , React.createElement(Typography.Title, {
              level: 4,
              style: {
                fontWeight: "bold",
                paddingTop: "2%",
                paddingLeft: "2%",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 301}}
, "Water Quality Index"

            )
            , React.createElement('div', { style: { width: "50%", height: "100%", margin: "0 auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 311}}
              , React.createElement('div', {
                style: {
                  padding: "5% 15% 15% 15%",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}}

                , wqiValues.value !== 0 ? (
                  React.createElement(React.Fragment, null
                    , React.createElement('div', {
                      style: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 319}}

                      , React.createElement('div', { style: { display: "flex", alignItems: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 327}}
                        , React.createElement('span', { style: { fontSize: "20px", fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 328}}, "WQI: "
                           , wqiValues.value
                        )
                      )

                      , React.createElement('span', { style: { fontSize: "16px", color: "#555" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 333}}, "Trend: "
                         , wqiValues.trend
                        , renderWQIArrow()
                      )
                    )
                    , React.createElement(MultiColorProgressbar, {
                      height: 30,
                      bars: wqiBars,
                      minVal: wqiValues.minVal,
                      maxVal: wqiValues.maxVal,
                      value: wqiValues.value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 338}}
                    )
                  )
                ) : (
                  React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 347}}, "Loading WQI data..."  )
                )
              )
            )
          )

          , React.createElement('div', {
            style: {
              width: "100%",
              borderRadius: "20px",
              backgroundColor: "white",
              padding: "1% 2%",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 353}}

            , React.createElement(WidgetStatic, {
              type: "lakeStats-widget",
              config: config, __self: this, __source: {fileName: _jsxFileName, lineNumber: 361}}
)
          )
        )
      )
    )
  );
};

export default LakeStats;
